<template lang="pug">
.container.max-w-4xl
  h3.text-3xl.font-bold Nuestra promesa
  p Sabemos que la mayoría de las dietas o programas de pérdida de peso que has probado en el pasado no funcionan, simplemente son demasiado difíciles de seguir. Enormes antojos de comida, amigos que te visitan con una botella de vino, esa tarta o esa pizza recién horneada… Todo afecta tu vida y si tu dieta no es fácil de seguir, no vas a lograr tus resultados.
  p Es posible comer lo que amas mientras cambias a un estilo de vida más saludable. Lo sabemos porque hemos trabajado con más de 2 millones de ketoers de todo el mundo y estamos felices de ser parte del cambio positivo en sus vidas.
  p Nuestros expertos en nutrición te brindarán una solución personalizada para obtener resultados sostenibles. Trabajamos duro para preparar la combinación perfecta entre una dieta fácil de seguir con recetas rápidas y deliciosas con solo tus ingredientes favoritos.
  p Pruébalo y te garantizamos que quedarás satisfecho.
  p.nutri Nutricionista profesional:
  p Dr. Butcher
  SignSvg.sign
</template>
<script>
import SignSvg from "@/components/analysis/sign.svg";
export default {
  components: {
    SignSvg,
  },
};
</script>
<style lang="scss" scoped>
p {
  @apply mt-6 font-medium italic;
}

.nutri {
  @apply font-bold;
  & + p {
    @apply mt-0 font-normal text-lg;
  }
}

.sign {
  width: 100%;
  max-width: 250px;
}
</style>