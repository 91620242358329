<template lang='pug'>
.w-full.bg-gray-100.py-12
  h2.font-medium.text-2xl.text-center La dieta Keto ha sido noticia en:
  .container
    .wrapper-brands
      .brand
        img.cosmopolitan(src="~@/assets/cosmopolitan.svg")
      .brand
        img.newyorktimes(src="~@/assets/new-york-times.svg")
      .brand
        img.naturalhealth(src="~@/assets/natural-health.svg")
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wrapper-brands {
  @apply grid grid-cols-3 gap-4 items-center mt-8;
}

.brand {
  @apply flex justify-center opacity-40;
}
.cosmopolitan {
  height: 35px;
}
.newyorktimes {
  height: 55px;
}
.naturalhealth {
  height: 35px;
}
</style>