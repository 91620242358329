<template lang="pug">
.box-clients
  img.img(:src="image") 
  .content
    .head 
      .user-name {{ name }}
      .user-loss-weight {{ lossWeight }}
    .description {{ description }}
</template>
<script>
export default {
  name: "box-clients",
  props: {
    name: String,
    lossWeight: String,
    description: String,
    image: String,
  },
};
</script>
<style lang="scss" scoped>
.box-clients {
  @apply border-2 border-green-500  rounded-lg overflow-hidden;
  .img {
    object-fit: cover;
    @apply w-full max-h-72 overflow-hidden;
  }
  .content {
    @apply p-4;
  }
  .head {
    .user-name {
      @apply font-semibold text-xl pb-2 leading-none;
    }
    .user-loss-weight {
      @apply text-sm text-white bg-green-500 py-1 px-4 rounded-full inline-block;
    }
  }
  .description {
    @apply py-2 text-sm font-medium text-gray-600;
  }
}
</style>