<template lang='pug'>
.background
.container.container-limited.pt-36.pb-8
  .flex.justify-center.flex-col
    .header.text-center
      h1.title Obtén tu <span>dieta personalizada</span> keto
      h2.subtitle La dieta más popular y con los resultados más rápidos! <br> Más de <b>470.000</b> personas confian en nosotros
  .flex.justify-center.mb-8
    .box-offer La oferta expira en: {{ timer }}
  .grid.grid-cols-2.py-8.gap-8.items-center
    h3 Elige el plan
    h3 Que incluye
    .flex.flex-col.gap-4
      div(v-for="plan in plansSubscription", :key="plansSubscription._id")
        BoxPricesComponent(:plan="plan", :id_offer_selected="selectedPlanID")
      .action-button Quiero mi plan!
    .flex.flex-col.gap-4
      .section-lapses
        .flex.flex-col
          .lapse-warraper
            .title-marker Dieta personalizada
            p.description-marker Obtendras tu dieta completamente personalizada y adaptada a los macronutrientes que necesitas en cada comida.
          .lapse-warraper
            .title-marker Seguimiento de progreso
            p.description-marker El sistema recalculara las cantidades de comida en base a tus progresos.
          .lapse-warraper
            .title-marker Cambios sin limites
            p.description-marker Podras añadir eliminar o modificar recetas a tu antojo, cambiar comidas o alimentos que no te gusten y añadir otros platos a tu dieta.
          .lapse-warraper
            .title-marker Acceso a todas las recetas
            p.description-marker Tenemos más de 3000 recetas keto entre desayunos, almuerzos, comidas, snacks... completamente adaptables a tu dieta!
          .lapse-warraper
            .title-marker Soporte 24/7
            p.description-marker Tendras un nutricionista que te ayudara a entender todo lo que te preocupe sobre la dieta keto, a que estas esperando?
.container.pb-24
  .title-2.text-center.mb-8 Experiencias <span>exitosas</span> de nuestros clientes
  .grid.grid-cols-2.gap-8
    .div(v-for="(client, index) in clients", :key="index")
      BoxClientsComponent(
        :name="client.name",
        :lossWeight="client.lossWeight",
        :description="client.description",
        :image="client.image"
      )
KetoBrandsComponent.mb-24
.container.container-limited.pb-24
  .grid.grid-cols-2.py-8.gap-8
    .flex.flex-col.gap-4.list-benefits
      h3 Beneficios de nuestra dieta
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Perdida de peso continuada sin sufrimiento
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Modifica la dieta a tu gusto, nosotros nos encargamos de hacer los calculos para que las recetas se adapten a ti
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Seguimiento de tus progresos y adaptación para continuar mejorando la dieta
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Disminuye el colesterol "malo" LDL, los triglicéridos en la sangre, los marcadores inflamatorios y el azúcar en la sangre
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Aumenta la calidad del sueño, acelera tu metabolismo
      .box-benefits.flex
        svgIcon.icon(:path="mdiCheckBold", type="mdi", :size="24")
        p Plataforma facil y guiada, soporte y asesoramiento incluido!

    .flex.flex-col.gap-4
      div(v-for="plan in plansSubscription", :key="plansSubscription._id")
        BoxPricesComponent(:plan="plan", :id_offer_selected="selectedPlanID")
      .action-button Quiero mi plan!
KetoDoctorPromise.pb-24
router-view
</template>
<script>
import { ref, computed } from "vue";
import BoxPricesComponent from "@/components/checkout/box-prices.vue";
import BoxClientsComponent from "@/components/checkout/box-clients.vue";
import KetoBrandsComponent from "@/components/shared/keto.brands";
import KetoDoctorPromise from "@/components/shared/doctor.promise";
import { mdiCheckBold } from "@mdi/js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {
    BoxPricesComponent,
    BoxClientsComponent,
    KetoBrandsComponent,
    KetoDoctorPromise,
  },
  data: () => ({
    mdiCheckBold,
    clients: [
      {
        name: "Marisa L.",
        lossWeight: "Perdio 6kg en 3 semanas.",
        image:
          "https://image.shutterstock.com/image-photo/authentic-real-amateurish-before-after-600w-1041338800.jpg",
        description:
          "Despues de probar diferentes dietas, todas me hacian sentir mal, dolores de estomago, mareos y cansancio continuado, hasta que encontre la dieta Keto. En solo 3 semanas perdí mis primeros 6 kilos, mejoro mi estado de animo y además las recetas son deliciosas, sin duda recomiedo que lo pruebes!",
      },
      {
        name: "Hester F.",
        lossWeight: "Perdio 8kg en 5 semanas.",
        image:
          "https://image.shutterstock.com/image-photo/real-before-after-weight-loss-600w-541208512.jpg",
        description:
          "Despues de probar diferentes dietas, todas me hacian sentir mal, dolores de estomago, mareos y cansancio continuado, hasta que encontre la dieta Keto. En solo 3 semanas perdí mis primeros 6 kilos, mejoro mi estado de animo y además las recetas son deliciosas, sin duda recomiedo que lo pruebes!",
      },
      {
        name: "Miranda G.",
        lossWeight: "Perdio 3kg en 2 semanas.",
        image:
          "https://image.shutterstock.com/image-photo/before-after-weight-loss-600w-768300016.jpg",
        description:
          "Despues de probar diferentes dietas, todas me hacian sentir mal, dolores de estomago, mareos y cansancio continuado, hasta que encontre la dieta Keto. En solo 3 semanas perdí mis primeros 6 kilos, mejoro mi estado de animo y además las recetas son deliciosas, sin duda recomiedo que lo pruebes!",
      },
      {
        name: "Lucia L.",
        lossWeight: "Perdio 12kg en 8 semanas.",
        image:
          "https://image.shutterstock.com/image-photo/before-after-weight-loss-rejuvenation-600w-320964476.jpg",
        description:
          "Despues de probar diferentes dietas, todas me hacian sentir mal, dolores de estomago, mareos y cansancio continuado, hasta que encontre la dieta Keto. En solo 3 semanas perdí mis primeros 6 kilos, mejoro mi estado de animo y además las recetas son deliciosas, sin duda recomiedo que lo pruebes!",
      },
    ],
  }),
  setup() {
    const store = useStore();
    ///TIMER OFFER
    const totalTime = 900000;
    const timeOffer = ref(totalTime);
    const msToTime = (duration) => {
      let s = Math.floor((duration / 1000) % 60),
        m = Math.floor((duration / (1000 * 60)) % 60);

      const minutes = m < 10 ? "0" + m : m;
      const seconds = s < 10 ? "0" + s : s;
      return minutes + ":" + seconds;
    };
    const timer = computed(() => msToTime(timeOffer.value));
    setInterval(() => {
      timeOffer.value = timeOffer.value - 1000;
      if (timeOffer.value == 0) timeOffer.value = totalTime;
    }, 1000);
    ///TIMER OFFER

    const plansSubscription = computed(
      () => store.getters["plansSubscriptionsStore/getListOrdered"]
    );

    const selectedPlanID = computed(
      () => store.getters["plansSubscriptionsStore/getSelectedPlan"]
    );

    return {
      timer,
      plansSubscription,
      selectedPlanID,
    };
  },
};
</script>
<style lang="scss" scoped>
.container-limited {
  max-width: 960px;
}

.subtitle {
  @apply text-2xl pt-4 pb-6 leading-8;
}

.box-offer {
  @apply p-4 border-2 border-red-500 rounded-xl text-center font-semibold text-lg bg-red-500 text-white max-w-2xl w-full;
}

h3 {
  @apply text-center font-semibold text-2xl pb-4;
}

.action-button {
  @apply text-center mt-6 bg-green-500 text-white text-xl font-bold rounded-lg py-4;
}

.section-lapses {
  .lapse-warraper {
    @apply border-l-2 border-green-400 pl-8;

    .title-marker {
      @apply font-medium flex items-center;

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        @apply bg-green-600 p-1 border-2 border-green-300;
        margin-left: -2.4rem;
      }
    }

    .description-marker {
      @apply text-sm;
    }

    & + .lapse-warraper {
      @apply pt-4;
    }
  }
}

.list-benefits {
  h3 {
    @apply text-left;
  }
  .box-benefits {
    @apply text-sm font-medium;
    & + .box-benefits {
      @apply pt-2;
    }

    .icon {
      @apply flex-shrink-0 pb-1 text-green-600 pr-2;
    }
  }
}
</style>