
import { computed, PropType, toRefs } from "vue";
import { SubscriptionPlanEntity } from "@/app/entities/subcription.plan.entity";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "box-prices",
  props: {
    plan: {
      type: Object as PropType<SubscriptionPlanEntity>,
      required: true,
    },
    id_offer_selected: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const store = useStore();
    const router = useRouter();
    const { plan, id_offer_selected } = toRefs(props);
    const duration = plan.value.billing_months || 0;
    const priceWeek = plan.value.priceWeek() || 0;
    const billingMonths = plan.value.billing_months || 0;
    const isSelected = computed(
      () => plan.value._id == id_offer_selected.value
    );

    const changePlan = () => {
      store.dispatch("plansSubscriptionsStore/changePlan", plan.value._id);
      router.push({ name: "Payment" });
    };
    return {
      duration,
      priceWeek,
      billingMonths,
      isSelected,
      changePlan,
    };
  },
};
